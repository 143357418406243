export const BaseUrl = "https://api.xpresscure.com/api";

// export const BaseUrl = "https://dev.xpresscure.com/api";
// export const BaseUrl = "http://3.21.180.84:5000/api";
// export const BaseUrl = "http://192.168.1.3:5000/api";
// export const BaseUrl = "http://192.168.1.5:5000/api";
// export const BaseUrl = "http://192.168.1.13:5000/api";
// export const BaseUrl = "http://localhost:5000/api";
// 18.118.199.109
// Doctor 
export const Doctors = "/doctor_list";
export const DoctorPagination = "/doctor_with_pagination"
export const doctorSortByLastLogin = "/doctor_with_pagination_sortByAppLogin";
export const DoctorInfo = "/admin/doctor_info_by_id";
export const AppraisalInfo = "/admin/employee_info";
export const viewLeaveManagement = "/admin/view_emp_data";
export const status_for_leave = "/admin/verify_leave";
export const AddStatus = "/admin/statusoftheday"
export const UploadCert = "/healthworker/e_certificate_add"
export const GeneRateQR = "/admin/generate_qrcode"
export const allocateNewDoctor = "/admin/allocateNewDoctor"
export const generateQrforPrint = "/generatePrintQr"
export const generateQrforPrintDoctor="/admin/generatePrintQrForDoctor"
export const DoctorInfoUpdate = "/admin/doctor_edit_profile";
export const DoctorMitraInfoUpdate="/updateDoctorMitraAccount"
export const dispatchKit="/dispatchKit"
export const updateKitData="/updateKitData"
export const depList = "/admin/depList"
export const AllPromotionList = "/admin/AllPromotionList"
export const AllDmKits = "/admin/AllDmKits"
export const searchDMKits = "/admin/searchDMKits"
export const PromotionSearch = "/admin/PromotionSearch"
export const DoctorListForAllot="/admin/DoctorListByDepartmentForAllocate"
export const HWListDeparment="/health_worker/list_department"
export const ActionWiseUserData="/admin/totalActionWiseUserData"
export const ActionWiseUserDetails="/admin/totalActionWiseUserDetails"
export const ActionWiseUserDataFordr="/admin/totalActionWiseUserDataForDr"
export const ActionWiseToUser="/admin/sendVatiMsgActionWiseToUser"
export const ActionWiseToUserFordr="/admin/sendVatiMsgActionWiseToDoctor"
export const editSlider="/admin/editSliderInDisplayImage"
export const SlidersOfDisplayImage="/admin/getSlidersOfDisplayImage"
export const createPromotion="/admin/createPromotion"
export const remove_promotion="/admin/remove_promotion"
export const delete_promotion="/admin/delete_promotion"
export const updatePromotion="/admin/updatePromotion"
export const promotionDetails="/admin/promotionDetails"
export const diseaseListApi = "/admin/diseaseList"
export const AllUserListForChat = "/admin/AllUserListForChat"
export const employeeInfoUpdate = "/admin/edit_employee_data";
export const registerDoctor = "/admin/doctor_registration";
export const doctorAppointment = "/admin/list_patient";
export const searchPatientForAll = "/admin/searchPatientForAll"
export const searchPatient = "/admin/searchPatientForPatientApp";
export const GeneralUserList = "/admin/generalUsersList_Account"
export const GeneralUserGuestList = "/admin/generalUsersList_Guest"
export const doctorApplications = "/admin/doctor_application_list";
export const demoRequests = "/admin/GetAllDemoRequest";
export const tryingToSubscribe = "/admin/DoctorMitraTryingToSubscribe";
export const unverifiedDoctorBysearch = "/admin/unverified_doctor_bysearch";
export const doctorManageStatus = "/admin/doctor_manage_status";
export const doctorSearch = "/admin/doctor_by_search";
export const employeeSearch = "/admin/search_employee";
export const doctSubCategories = "/doctor/doctSubCategories";
export const deleteDoctor = "/admin/doctor_remove";
export const onlineDoctor = "/admin/online_doctor_List";
//
export const TempSendMsg = "/admin/send_msg"
export const ChooseTemp = "/admin/temp_list"
export const TempSearch = "/admin/search_hw"
export const TempList = "/admin/list_temp_msg"
export const ViewCallDetails = "/healthwoker/viewCalldetails"
export const ViewLeadCallDetails = "/healthwoker/viewLeadCalldetails"
export const AddCallDetails = "/healthwoker/Submit_call_log"
export const EngageOnWhatsApp = "/healthwoker/EngageOnWhatsApp"
export const ChangeLeadStatus = "/healthworker/ChangeLeadStatus"
export const transferToSupport = "/healthworker/transferToSupport"
export const AddLeadCallDetails = "/healthwoker/Submit_Lead_call_log"
export const LastCallhelthworkers = "/healthwoker/sortBylastCallTime"
export const getKitReceived = "/healthwoker/getKitReceived"
export const getAddressUpdated = "/healthwoker/getAddressUpdated"

//  Doctor Mitra
export const registerHealthWorker = "/admin/register_health_worker";
export const getDoctorMitra = "/admin/list_healthworkers";
export const getDoctorMitraCall = "/healthwoker/UnverifyHwLastCall"
export const getDMLastLogin = '/admin/recent/UnverifyRecentLogin';
export const getDMReferral = '/admin/recent/referralData';
export const getDMInterestLevel = '/admin/recent/UnverifyHwSortByIntrestLevel';
export const getArea = "/doctor/list_area_of_interest";
export const ApproveDemo = "/admin/ApproveDemoRequest"
export const VerifyDmSerach = "/admin/searchVarifyMitra"
export const getAreaByDept = "/doctor/area_of_interest_list"
export const searchHelthworkers = "/admin/search_helthworkers";
export const healthworkerInfo = "/admin/healthworker_information";
export const DmInfo = "/refer/checkReferedstatusforAdmin";
export const addBlogCategory = "/admin/add_blog_category";
export const getBlogCategory = "/blog_cat_list";
export const ExpiredHealthworkerData="/admin/recent/ExpiredHealthworkerData"
export const SupportCallData="/admin/recent/SupportCallData"
export const UserByPlan="/admin/recent/UserByPlan"
export const SupportUserDataNonSubscriber="/admin/recent/SupportUserDataNonSubscriber"
export const Varifyhelthworkers = "/admin/verify_helthworkers";
export const varifyHWLastLogin = '/admin/recent/VerifyRecentLogin';
export const varifyHWAll = '/admin/recent/VerifyHWAll';
export const varifyHWInterestLevel = '/admin/recent/VerifyInterestLevel';
export const manageStatusHealthworker = "/admin/manage_status_healthworker";
export const courseList = "/health_worker/course_list";
export const DocCourseList = "/doctor/list_courses";
export const EditDMInfo = '/admin/edit_healthworker';

export const DoctorAddToDept = "/admin/addDoctIndepartment";
export const DocMitraAddToDept = "/admin/addDoctorMitraIndepartment";
export const DoctorGetDept = "/admin/doctorListOnDeparment";
export const DocMitraGetDept = "/admin/doctorMitraListOnDeparment";
export const DoctorRemoveFromDept = "/admin/deleteDoctorByDepartment";
export const DocMitraRemoveFromDept = "/admin/deleteDoctorMitraByDepartment";
// http://18.220.239.106:5000/api/health_worker/course_list
//http://18.220.239.106:5000/api/admin/verify_helthworkers
// http://18.220.239.106:5000/api/admin/healthworker_information?id=605dedc6d3d22303aeda9d21

// comman api
export const getCity = "/health_worker/list_district ";
export const stateList = "/health_worker/list_state";
export const getBanks = "/bank_list";
export const getClg = "/doctor/collage_list";
export const pgCourses = "/doctor/pg_list";
export const getSpecilization = "/doctor/specilization_list";
export const getCertificate = "/doctor/certificate_list";

// Accounts
// Doctors
export const doctorList = "/admin/doctorListApi";
export const monthlyRecords = "/admin/doctorPaymentRecList";
export const searchDoctorPayment = "/admin/searchDoctorPaymentRecList";
export const DMTListByDate = "/admin/DoctordataBydate?";
export const DocEarningListByDate = "/admin/DoctorTransactionHistoryListBydate";
export const XCEarningListByDate = "/admin/XpressCureAccountList";
export const SupportStaffList = "/admin/supportlist";
export const ClinicKitSentData = "/admin/clinickitsentdata"
export const doctorPayment = "/admin/addPaymentRecord";
export const doctorRecordInfo = "/admin/doctorRecordInfo";
export const doctorTransactionList = "/admin/doctorTransactionList";
export const DTHListBydate = "/admin/DoctorTransactionHistoryListBydate";
export const userListByDate = "/admin/userTransactionHistoryListBydate";
export const searchDTHList = "/admin/searchDoctorTransactionList";
export const searchUserList = "/admin/searchUserTransactionList";
// Docotr Mitra
export const ListOfHealthWorker = "/admin/listOfHealthWorker";
//
export const DMPaymentRecordList = "/admin/doctorMitraPaymentRecordList";
export const addPaymentHealthworker = "/admin/addPaymentRecordForHealthworker";
export const searchpaymentDM = "/admin/searchPaymentRecordForHealthworker";
export const doctorMitraRecordInfo = "/admin/doctorMitraRecordInfo";
export const dmAllTransationsHistory = "/admin/doctorTransactiondetails";
export const rewardList = "/healthworker/rewardList";
export const DMPaymentRecordBYDate = "/admin/dataBydate";

// http://18.220.239.106:5000/api/admin/doctorTransactiondetails?pageNumber=0&doctMitra_id=605dedc6d3d22303aeda9d21
// Doctor Mistra Recharge Management
export const rechargeListHealthworker = "/admin/rechargeListHealthworker";
export const searchMitraRecharge = "/admin/searchMitraRecharge";
export const addRecharge = "/admin/addRecharge";
export const RechargeListBydate = "/admin/RechargeListBydate";

// Doctor Mitra Subscription Management
export const doctorMitrasubsList = "/admin/doctorMitrasubsList";
export const addMitraSubscription = "/admin/addMitraSubscription";
export const SubcriptiondataBydate = "/admin/SubcriptiondataBydate";
export const searchMitraSubscription = "/admin/searchMitraSubscription";
// http://18.220.239.106:5000/api/admin/doctorMitrasubsList?pageNumber=0

//  Doctor Mitra Transaction History

export const doctorMitraTransactionList = "/admin/doctorMitraTransactionList";
export const searchDMTransactionList =
  "/admin/searchDoctorMitraTransactionList";
export const DMTHistoryListBydate =
  "/admin/DoctorMitraTransactionHistoryListBydate";
// http://18.220.239.106:5000/api/admin/doctorMitraTransactionList?pageNumber=0
//
export const patientTransactionList = "/admin/patientTransactionList";
export const searchPatientTransactionList =
  "/admin/searchPatientTransactionList";
export const addChatAssistantDetail = "/admin/addChatAssistant";
export const expenseMasteradd = "/admin/expenseMasteradd";
export const expenseMasterlist = "/admin/expenseMasterlist";
// control penal api all Specilization
export const allSpecilization = "/doctor/list_department";
export const dmMapData = "/admin/latitude_logintude_dm";
export const docMapData = "/admin/latitude_logintude_dr";
export const liveConsultData = "/admin/patientDashboard";
export const allSpecilizationList = "/admin/list_department";
export const allPlanList = "/admin/dmplanslistWithPagination";
export const planListWithoutPagination = '/admin/allPlans';
export const allRewardsList = "/admin/getrewardslist";
export const allGlobalEventList = "/admin/listOfEvent";
export const globalListWithStatus = "/admin/global_event/view_event_by_status";
export const dailyListWithStatus = "/admin/dailyEvent/Event_List";
export const activeInactiveGlobalStatus = "/admin/global_event/Inactive_Active_event_button";
export const allUpdateMasterList = "/admin/get_Updates_List";
export const UpdateMasterList = "/admin/Updates_List"
export const dailyRewardActive = "/admin/dailyEvent/ActiveDailyEvent";
export const dailyCurrentEvent = '/admin/dailyEvent/StartDailyEvent';
export const dailyPauseEvent = '/admin/dailyEvent/PauseDailyEvent';
export const freePlanSubscribeApi = '/healthworker/subscription_for_cureFree';
export const SupportTeamSubscribedUser="/admin/SupportTeamSubscribedUser"
export const allCouponList = "/admin/couponMasterAllList";
export const discountCouponList = "/admin/discountCouponsList"
export const activeCouponsList = "/admin/activeCouponsList"
export const activeCouponsListforAssignment = "/admin/activeCouponsListforAssignment"
export const getWATemplateList = "/admin/getWATemplates"
export const offerListInPlan = "/admin/offerListInPlan"
export const updateCoupon = "/admin/updateCoupon"
// export const allPlanList = "/admin/alldmplanlists";
export const editPlanDetails = "/admin/plan";
export const addSpecilization = "/admin/add_department";
export const diseaseWiseConsult="/admin/diseaseWiseConsult"
export const addReward = "/admin/add_rewards";
export const addGlobalEvent = "/admin/addEvent";
export const addDailyReward = "/admin/dailyEvent/addEvent";
export const activeEventData = "/admin/active_event";
export const start_end_btn = "/admin/start_end_button";
export const demoConsultDM = "/healthworker/demoConsultation";
export const addUpdateMaster = "/admin/create_Updates_For_DM_app";
export const editGlobalEvent = "/admin/edit_event";
export const editDailyEvent = "/admin/dailyEvent/editEvent";
export const editUpdateMaster = "/admin/editUpdatesbyid";
export const AllLeads="/healthworker/AllLeads"
export const CreatAccountForLeads="/healthworker/CreatAccountForLeads"
export const AddLeads="/admin/AddLeads"
export const assignSupportStaff="/admin/assignSupportStaff"
export const assignCoupon="/admin/assignCoupon"
export const pinHealthWorker="/admin/pinHealthWorker"
export const assignSupportStafftoLead="/admin/assignSupportStafftoLead"
export const viewGlobalEvent = "/admin/view_event_details";
export const getVatiMsg="/admin/getVatiMsg"
export const getUserActions="/admin/getUserActions"
export const UserActionCountById="/admin/UserActionCountById"
export const UserActionAnalysis="/stats/dm/UserActivityAnalysis"
export const getCallDetails = "/stats/dm/getCallDetails"
export const getInterestedUsers = "/stats/dm/getInterestedUsers"
export const performanceData = "/stats/dm/performanceData"
export const listOfTransactionsCategory="/admin/listOfTransactionsCategory"
export const activity_lists="/admin/activity_lists"
export const addSlider="/admin/addSliderInDisplayImage"

export const DoctorMitraWithRating="/admin/getDoctorMitraWithRating"
export const DoctorMitraWithRatingFollowup="/admin/getDoctorMitraWithRatingFollowup"
export const findNextCall="/admin/findNextCall"
export const getDoctorLog="/admin/getDoctorLog"
export const startAKnowlarityCall="/startAKnowlarityCall"
export const generatePaymentLinks = "/generatePaymentLinks"
export const updateCallLog="/admin/updateCallLog"
export const getDoctorMitraNeverAssigned="/admin/getDoctorMitraNeverAssigned"
export const getDoctorMitraTodayCall="/admin/getDoctorMitraTodayCall"
export const getDoctorMitraLastCall="/admin/getDoctorMitraLastCall"
export const getDoctorMitraNeverCalled="/admin/getDoctorMitraNeverCalled"
export const DoctorMitraExpiryData = "/admin/getDoctorMitraExpiryData"
export const JobList="/admin/job_list"
export const AddJob="/admin/add_jobs"
export const EditJob="/admin/edit_jobs"
export const DoctorMitraScoring="/admin/DoctorMitraScoring"
export const addRewardbyId = "/admin/add_rewards/:id";
export const interestLevel = '/admin/interest_level';
export const interestLevelDR = '/admin/interest_levelFor_dr';
export const DoctorByDepart = '/admin/DoctorListByDepartment';
export const DoctormitraByDepart = '/admin/DoctorMitraListByDepartment'
export const editStatusDocByDepart = "/admin/EditDoctorDetialsInDocByDept";
export const editStatusDocMitraByDepart = "/admin/UpdateInDocmitraByDept "
export const lockUnlock = "/admin/changeAllDoctorStatus";
export const AvailableAllDoctorMitra = "/admin/AvailableAllDoctorMitra"
export const rewardInfo = "/admin/detais_view_byId";
export const ActiveReward = "/admin/viewdetails_by_status"
export const editReward = "/admin/editrewardbyid";
export const AddCoupon = "/admin/addcouponcode";
export const deleteReward = "/admin/removeReward";
export const editStatus = "/admin/edit_rewards";
export const unLockStatus = "/admin/weekly_reward/unlock_button";
export const inactiveStatus = "/admin/weekly_reward/inActive_button";
export const activeStatus = "/admin/weekly_reward/active_button";
export const nextEvent = "/admin/weekly_reward/NextUpComing_button";
export const currentStatus = "/admin/weekly_reward/start_button";
export const expiredStatus = "/admin/weekly_reward/end_button";
export const deleteSpecilization = "/admin/remove_department";
export const deleteCoupon = "/admin/Couponremove/";
export const deleteDmPlan = "/admin/plan";
export const departmentInfo = "/admin/departmentInfo";
export const editDepartment = "/admin/edit_department";
export const editCoupon = "/admin/couponedit";
export const editPlan = "/admin/plans";
export const editOrgnization = "/healthworker/organization_list";
export const editSubCategory = "/doctor/all_category";
export const editMitraSubCategory = "/admin/editdoctorMitraSubcate";
export const ViewPlanDetails = "/admin/viewPlandetails"
export const doctorMitraSubcategoryInfo = "/admin/doctorMitraSubCategoryInfo"
export const MitrasubCategoryInfo = "/admin/doctorMitraSubCategoryInfo"
export const Mitrafees = "/admin/editdoctorMitraSubcategoryfees"
export const policyList = '/admin/liveStatusRewardApi'
// control penal api all Collage
export const editPlanReward = "/admin/editrewardnamebyid";
export const editPlanLevel = "/admin/edit_challenge_level";
export const subCategory = "/admin/doctorMitraSubCategoriesList";
export const subCategory_list = "/admin/doctorMitraSubCategories";
export const DoctersubCategory = '/doctor/doctSubCategories'



export const addPlanProp = '/admin/addplanproperties'
export const editPlanProp = '/admin/editplanpropertiesbyid'
export const addPlanReward = '/admin/add_rewards'
export const listallCollege = "/admin/lists_collages"
export const listCollage = "/admin/list_collage";
export const addCollage = "/admin/add_collage";
export const removeCollage = "/admin/remove_collage";
export const collegeInfo = "/admin/collegeInfo";
export const editCollege = "/admin/edit_collage";
// 18.118.199.109:5000/api/admin/collegeInfo?clg_id=604cb7649ecfc5466d394476
// control penal api all Degree
export const degreeList = "/admin/course_listFor_admin";
export const addDegree = "/health_worker/add_course";
export const editDegree = "/health_worker/editCourse";
export const deleteDegree = "/health_worker/deleteCourse";
export const Getdegreeinfo = "/admin/courseInfo";
export const editCourse = "/health_worker/editCourse";

// 18.118.199.109:5000/api/admin/courseInfo?course_id=6045f1ade0d32010691088d3
// control penal api all Specilization
export const complainList = "/admin/list_disease";
export const addCompalins = "/admin/add_disease"; //category
export const deleteComplains = "/admin/remove_disease";
export const getComplain = "/admin/diseaseInfo";
export const exitComplain = "/admin/edit_disease";

// curl --location --request PUT 'http://18.220.239.106:5000/api/admin/edit_disease' \
// --form 'disease_name="Arthritis disease"' \
// --form 'diseaseId="604351282171c14ba0a7139d"' \
// control penal api all Category
export const CategoryList = "/admin/categoryList";
export const addCategory = "/doctor/create_cat";
export const deleteCategory = "/admin/deletecategory"; //?cate_id'
export const categoryInfo = "/admin/categoryInfo";
export const editcategory = "/admin/editcategory";
//
export const subCategory1 = "/doctor/doctSubCategories";
export const deleteSubcategory = "/doctor/deleteSubcategory";
export const deleteMitraSubcategory = "/admin/deleteDoctorMitraSubcategory"
export const subCategoryInfo = "/admin/subCategoryInfo";
export const Addsubcategory = "/doctor/create_subCat";
export const AddMitrasubcategory = "/admin/createDoctorMitraSubCat";
export const editSubcate = "/doctor/editSubcate";
// 18.118.199.109:5000/api/admin/subCategoryInfo?subCate_id=609bc72d94aeeb45c68636b4
// 18.118.199.109:5000/api/doctor/deleteSubcategory?subCate_id=609bc460d86a093faae258af
//
export const states = "/health_worker/states ";
export const doctSubCategory = "/doctor/all_category";
export const subCategoryBycat = "admin/subCateByCategory";
export const doctorAllCategory = "/doctor/all_category";
//
export const CityList = "/admin/list_city";
export const removeCity = "/admin/remove_city";
export const addCity = "/admin/add_city";
export const cityInfo = "/admin/cityInfo";
export const Editcity = "/admin/edit_city";
// 18.118.199.109:5000/api/admin/remove_city?cityId=60d31d9628fba8429d201c54
// 18.118.199.109:5000/api/admin/cityInfo?city_id=6040bf0f99d7832731b03df5
export const addFeesMaster = "/admin/add_fees";
export const feesMaster = "/admin/list_fees";
export const expenseMgmtMaster = "/admin/expenseMasterlist";
export const expenseList = "/admin/expenseMasterAllList";
export const addAppresial = "/admin/addAppraisal";
export const addLeaves = "/admin/apply_for_leave";
export const employeeList = "/admin/emp_list";
export const appraisalListData = "/admin/list_employee";
export const leavesListData = "/admin/list_for_verify_emp";
export const expenseData = "/admin/expense_management_add";
export const updateExpenseEdit = "/admin/expense_management_edit";
export const updateExpenseRemove = "/admin/expense_management_remove";
export const totalRecordsExpenseType = "/admin/expense_management_ListBydate";

export const expenseDataList = "/admin/expense_management_list";

export const removeFees = "/admin/remove_fees";
export const removeExpenseItem = "/admin/expenseMasterremove"
export const feesInfo = "/admin/fees_info";
export const editFees = "/admin/edit_fees";
export const editExpenseItem = "/admin/expenseMasteredit"
//control panel Presciption Master daignosis
export const addDaignosis = "/doctor/add_daignosis";
export const daignosisList = "/doctor/filter_daignosis";
export const daignosisInfo = "/admin/daignosis_info";
export const removeDaignosis = "/doctor/remove_daignosis";
export const editDaignosis = "/doctor/edit_daignosis";
//control panel Presciption Dosage Master
export const listDosage = "/admin/list_dosage";
export const dosageInfo = "/admin/dosage_info";
export const addDosage = "/admin/add_dosage";
export const deleteDosage = "/admin/delete_dosage";
export const updateDosage = "/admin/update_dosage";
//control panel Presciption Allergies Master
export const listAlergies = '/doctor/list_alergies'
export const addAlergies = '/doctor/add_alergies'
export const editAlergies = '/doctor/edit_alergies'
export const removeAlergies = '/doctor/remove_alergies'
export const alergiesInfo = '/admin/alergies_info'
//
//control panel Presciption Duration Master

export const lissDurationMaster = '/admin/list_durationMaster'
export const addDurationMaster = '/admin/add_durationMaster'
export const durationInfo = '/admin/duration_info'
export const updateDurationMaster = '/admin/update_durationMaster'
export const deleteDuration = '/admin/delete_duration'
//control panel doctor Fees Masters
export const doctorFeesMasters = "/admin/doct_fees_list";
export const addDoctorFeesMaster = "/admin/addDoctorFees";
export const doctorRemoveFees = '/admin/doctor_remove_fees'
export const doctorChargeInfo = '/admin/doctorChargeInfo'
export const doctorEditFees = '/admin/doctor_edit_fees'

//control panel Doctor Mitra Service Charge Master
export const DoctorMitraFees = "/admin/health_fees_list";
export const addDoctorMitraService = "/admin/healthworker_add_fees";
export const DoctorMitraFeesRemove = '/admin/healthworker_remove_fees'
export const editDoctorMitraFees = '/admin/healthworker_edit_fees'
export const infoDoctorMitraFees = '/admin/doctorMitraChargeInfo'

//control panel FAQ
export const faqList = "/admin/listFaq";
export const faq = "/admin/faq";
export const addFAQ = "/admin/healthworker_add_fees";
export const removeFAQ = '/admin/healthworker_remove_fees'
export const editFAQ = '/admin/healthworker_edit_fees'
export const infoFAQ = '/admin/DoctorFaqInfo'

// Doctor Review & Ratings

export const DoctorRatingList = '/admin/DoctorRatingList'
export const SearchDoctorRating = '/admin/SearchDoctorRatingList'
export const searchByDateRating = '/admin/searchByDate'
export const DoctorRatingremove = '/admin/DoctorRatingremove'
export const DoctorRatingInform = '/admin/DoctorRatingInform'

// Doctor Mitra Review & Ratings
export const DoctorMitraRatingList = '/admin/DoctorMitraRatingList'
export const SearchDoctorMitrsaRating = '/admin/SearchDoctorMitraRatingList'
export const searchByDateRatingMitra = '/admin/DoctorMitraRatingsearchByDate'
export const DoctorMitraRatingremove = '/admin/doctorMitraRatingremove'
export const DoctorMitraRatingInform = '/admin/DoctorMitraRatingInform'
// 18.118.199.109:5000/api/admin/DoctorMitraRatingList?pageNumber=0 rat_id

//Lablist
export const GetLabList = '/admin/lab_list';
export const SearchLab = '/admin/searchLab';
export const DeleteLab = '/admin/remove_lab';
export const GetLabDetail = '/admin/labinformation';
export const EditLabDetail = '/admin/edit_lab';
export const AddLab = '/admin/add_lab';

//Medicine
export const GetMedList = '/admin/list_medicine';
export const SearchMed = '/admin/searchMedicine';
export const DeleteMed = '/admin/remove_medicine';
export const GetMedInfo = '/admin/medicine_info';
export const EditMedDetail = '/admin/editMedicineInfo';
export const AddMed = '/admin/add_medicine';

//ChatBot
export const GetBotQuestionList = '/admin/allQuestionList';
export const GetQuestionByDept = '/admin/questionByDepartment';
export const EditBotQuestion = '/admin/editQuestion';
export const AddBotQuestion = '/admin/addJiaQuestions';
export const DeleteBotQuestions = '/admin/deleteQuestion';
export const GetQuestionInfo = '/admin/jiaQuestionInfo';

//Blog
export const GetBlogList = '/admin/blogs_lists';
export const GetBlogInfo = '/admin/blog_info'
export const EditBlog = '/admin/edit_blog';
export const DeleteBlog = '/admin/remove_blog'
export const SearchBlog = '/admin/blog_search';
export const AddBlog = '/admin/add_blog';

//Blog Category
export const GetBlogCategoryList = '/admin/blogcategories';

//Blog Sub Category
export const GetBlogSubCategoryList = '/admin/blog_subcat_list';

//Patient
export const PatientInformation = '/admin/patientInformation';
export const PatientConsultationHistory = '/admin/consultationHistory';


//Accounts Section 2
export const GetMitraRecordsByDate = '/admin/dataBydate';
// <<<<<<< HEAD
export const weeklyAchieve = "/admin/reward_gifts_achiver";
export const dailyAchieve = "/admin/dailyEvent/eventlist";
export const UsersActivitiesDetails = "/admin/AllUsersAction"

// export const weeklyAchieve = "/admin/reward_gifts_achiver";
export const policyListReward = "/admin/AllRewardLiveStatusActive";
export const allRewardList = "/admin/WeeklyCurrentCompletedReward_By_PolicyID";
export const rewardRank = '/admin/Weekly_rewrd_detialsInHW';
// >>>>>>> 5635758165e0ca281a8ef0e454e9cfa0b949fdc7
export const globalAchieve = "/admin/view_event_by_id";
export const winnerList = '/admin/global_event/winners_details'
export const rankListData = "/admin/ranking_list";
export const dailyListData = '/admin/dailyEvent/daily_achivers'
export const signUpHW = "/healthworker/signuphw";
export const signUpLastLogin = '/admin/recent/signHwRecentData';
export const signUpDR = '/doctor/signupdr';
export const incompleteRegDR = '/doctor/mobile_verify_dr';
export const incompleteReg = "/healthworker/mobile_verify_hw";
export const SearchDoctorMitraPaymentDetails = '/admin/searchPaymentRecordForHealthworker'
export const EditPaymentPaymentDetails = '/admin/editPaymentRecordForHealthworker'
export const EditRechargeDetails = '/admin/addRecharge'
export const GetDoctorRecordsByDate = '/admin/DoctorDataByDate'

export const GetRechargeInfo = '/admin/rechargeInformation'
export const GetRechargeHistory = '/admin/rechargeHistoryList'
export const SubcriptionListWithId = '/healthworker/detailsfordata'
export const ConsultationListWithId = '/healthworker/clinic_details'
export const ConsultCountValue = '/healthworker/totalcasebyconsult'

export const GetLiveConsultation = '/admin/liveConsultation'
export const GetLiveFollowUp = '/admin/liveFollowUp'
export const closeConsult = "/healthworker/close_consultation";



export const GetDMConsultation = '/admin/allConsultation'
export const GetDMFollow = '/admin/allFollowUp'
export const GetXPConsultation = '/admin/allXpressConsultation'
export const GetXPFollow = '/admin/allXpressFollowUp'


export const GetMainTransactionList = '/admin/mainTransactionList'

export const GetFrequentData = '/admin/FrequentApiForAdmin'
export const CreateFrequentData = '/doctor/createDataOfFrequent'

export const getDoctorStatus = '/debug/docStatus'
export const getDMStatus = '/debug/dmstatus'
export const getPatientStatus = '/debug/patientStatus'

//Doctor Course
export const DoctorCourseList = '/admin/list_doctor_course'
export const DoctorCourseAdd = '/admin/addCourses'
export const DoctorCourseEdit = '/admin/edit_courses'
export const DoctorCourseInfo = '/admin/doctorCourseInfo'
export const DoctorCourseDelete = '/admin/remove_courses'

//Debugging
export const InitiateConsultation = '/healthworker/IntiateConsultation'
export const DoctorSuggestion = '/helthworker/doctor_suggestion'
export const CallResponse = '/doctor/chatAccepted_by_Doctor'
export const ListAllDisease = '/admin/lists_diseases'
export const CaseSummary = '/healthworker/summeryListApi'

//Stats
export const DashboardStats = '/stats/dashboard'
export const DoctorByCategory = '/stats/doctor/getdocbycategory'
export const Doctor_dashboard_graph = '/stats/doctor/doctor_graph'
export const Dm_dashboard_graph = '/stats/dm/docMitra_graph'
export const docMitra_graph_supportUser = '/stats/dm/docMitra_graph_supportUser'


export const DoctorDashboard = '/stats/doctor/dashboard'
export const DocIndivisual = '/stats/doctor/individualStats'
export const DMByCategory = '/stats/dm/getdmbycategory'
export const DMDashobard = '/stats/dm/dashboard'
export const DMIndivisual = '/stats/dm/individualStats'
export const DMPatBreakdown = '/stats/dm/getPatientBreakdown'
export const accountDashboard = '/stats/accountdashboard'

//Employee
export const AddEmployees = '/admin/add_employee'
export const ListEmployee = '/admin/list_employee'
export const addSalary = '/admin/addSalary'
export const GetEmployee = '/admin/employee_info'
export const DeleteEmployee = '/admin/remove_employee'
export const SearchEmployee = '/admin/search_employee'

//Login
export const LoginEndpoint = '/admin/loginUser'
export const FirebaseEndpoint = '/admin/updateFirebase'
export const LogoutEndpoint = '/admin/logoutUser'

//User Access
export const createUserAccess = '/admin/createUserAccess'
export const updateUserAccess = '/admin/updateUserAccess'
export const getUserAccess = '/admin/getUserAccess'
export const email_forgot_pass = '/admin/send_otp/forgot_password'
export const otp_forgot_pass = '/admin/verify_otp_emp'
export const new_forgot_pass = '/admin/update_password'

export const listUserAccess = '/admin/listUserAccess'


//Tickets
export const Ticket = '/admin/ticket';
export const Tickets = '/admin/tickets';
export const chat_data = '/admin/ticket_by_id';
export const resolved_ticket = '/admin/resolvedapi';
export const notAccept_ticket = '/admin/unacceptedticket';
export const accept_ticket = '/admin/acceptNotification';
export const resolved_issue_api = '/admin/resolvedticket';

//Withdrawal
export const WithdrawalList = '/admin/withdrawalAmountList';
export const WithdrawalApproveDM = '/admin/hw/approvedWithdrawalAmount';
export const WithdrawalApproveDoc = '/admin/dr/approvedWithdrawalAmountForDoctor';
export const WithdrawalAllDM = '/admin/sendWidrawalRequestToAllMitra';
export const WithdrawalAllDoc = '/admin/sendAllWidrawalRequestToDoctor';

//Enquiry
export const Enquiries = '/admin/enquiries';
export const Enquiry = '/admin/enquiry';

//Plans
export const Plans = '/admin/plans';
export const Plan = '/admin/plan';

//Email Template
export const Email_Template = '/admin/email_template';
// export const Getdegree = "/admin/list_degree";
// // export const addDegree = "/admin/add_degree";

// http://18.220.239.106:5000/api/admin/subCateByCategory?cat_id=609bc460d86a093faae258af
